import React from 'react';

// UMUM
export const HomePage = React.lazy(() => import('./HomePage'));
export const BisnisPage = React.lazy(() => import('./BisnisPage'));
export const LembagaPage = React.lazy(() => import('./LembagaPage'));
export const LiveStreaming = React.lazy(() => import('./LiveStreaming'));
export const PendidikanPage = React.lazy(() => import('./PendidikanPage'));

// KUMPULAN
export const Kumpulan = React.lazy(() => import('./Kumpulan'));

// DETAIL
export const Detailpage = React.lazy(() => import('./Detailpage'));
export const ContentDetail = React.lazy(() => import('./ContentDetail'));




export const ProtectedRoute = React.lazy(() => import('./ProtectedRoute'));